import { createSlice } from '@reduxjs/toolkit';

export interface User {
  email: string | undefined;
  phone: string | undefined;
  registrationDate: string | undefined;
  paidReferrals: number;
  totalReferrals: number;
  refferalUrl: string;
  uniqueQueries: number;
  projects: number;
  phrases: number;
  stores: number;
  salesStores: number;
  salesMonth: number;
  weeklyOrderSum: number;
  balance: number | undefined;
  tariffs: {
    phrase_count_min: number;
    phrase_count_max: number;
    value: number;
    title: string;
    price_per_day: number;
    price: number;
  }[];
  tariffFreeDays: number;
  dayPrice: number;
  bonusTime: {
    minutes: number;
    hours: number;
    days: number;
    totalMinutes: number;
    hasTime: boolean;
  };
  calculateTariff: undefined | number;
  isActiveAB: boolean;
  expirationABDate: string | false;
  isLoadingAccessSensitiveData: boolean;
  isLoadingUserBasicData: boolean;
  isLoadingFinanceData: boolean;
}

export interface Action<P> {
  type: string;
  payload: P;
}

const initialState: User = {
  email: undefined,
  phone: undefined,
  registrationDate: undefined,
  paidReferrals: 0,
  totalReferrals: 0,
  refferalUrl: '',
  uniqueQueries: 0,
  projects: 0,
  phrases: 0,
  stores: 0,
  salesStores: 0,
  salesMonth: 0,
  weeklyOrderSum: 0,
  balance: undefined,
  tariffs: [],
  tariffFreeDays: 0,
  dayPrice: 0,
  bonusTime: {
    minutes: 0,
    hours: 0,
    days: 0,
    totalMinutes: 0,
    hasTime: false,
  },
  calculateTariff: undefined,
  isActiveAB: false,
  expirationABDate: false,
  isLoadingAccessSensitiveData: true,
  isLoadingUserBasicData: true,
  isLoadingFinanceData: true,
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser: (state, action: Action<Partial<User>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
